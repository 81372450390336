/* THIS FILE CREATES
styles for workspace (account) management, including the creation and editing of an account and its users.
 */
/* This is the vendor import that contains items needed for most of the "main" files to build out correctly
it can be loaded multiple times because most of these files should not bulk up the final built file.
*/
/* Can't use this because we're using root-defined colors

@function set-secondary-font-color($color) {
	@if ($color == #fff) {
		@return #a2a2a2;
	} @else if (lightness($color) > 50) {
		@return darken($color, 28%)
	} @else {
		@return lighten($color, 20%);
	}
}
 */
.form_entry .form_content,
.form_entry .content-element:not(.ce-editor),
.prop-editor .form_content,
.prop-editor .content-element:not(.ce-editor) {
  width: 100%;
  max-width: 980px;
  margin: 0 auto 30px;
  padding: 25px;
  background: #fff;
  border-radius: 0;
  box-shadow: 0 0 10px rgba(213, 213, 213, 0.75);
}
@media (min-width: 1024px) {
  .form_entry .form_content,
.form_entry .content-element:not(.ce-editor),
.prop-editor .form_content,
.prop-editor .content-element:not(.ce-editor) {
    padding: 40px 80px;
  }
}

.form_entry label {
  color: rgb(var(--color-brand-main));
  font-weight: 500;
  line-height: 1;
}
.form_entry label:not(.tbl) {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin: 0 0.125rem;
}
.form_entry .question:first-of-type label {
  margin-top: 0;
}
.form_entry h2 {
  font-weight: 400;
  color: #7B7B7B;
}

.account-add,
.account-editor {
  max-width: 980px;
  margin: 0 auto;
}
.account-add .question .label-group-heading,
.account-editor .question .label-group-heading {
  display: block;
  width: 100%;
  max-width: 300px;
  background: #7B7B7B;
  color: #fff;
  line-height: 1.2;
  padding: 9px 13px 8px;
  font-size: 1.25rem;
  margin-bottom: 15px;
  margin-top: 45px;
  text-transform: none;
  font-weight: 300;
}
.account-add .question .labeldomain .instructions,
.account-editor .question .labeldomain .instructions {
  font-style: italic;
  margin: 5px 0;
}
.account-add .question .label-group-instructions,
.account-editor .question .label-group-instructions {
  font-style: italic;
  margin: 5px 0;
}
.account-add .question .label-group-labels,
.account-editor .question .label-group-labels {
  display: block;
  margin: 0.5rem 0;
}
.account-add .question.user-entry-required .form_field > span.label::after,
.account-add .question.user-entry-required .form_field > label::after,
.account-add .question.user-entry-required .prop-group > .props > span.label::after,
.account-add .question.user-entry-required .prop-group > .props > label::after,
.account-add .prop.user-entry-required > span.label::after,
.account-add .prop.user-entry-required > label::after,
.account-add .question .user-entry-required > span.label::after,
.account-add .question .user-entry-required > label::after,
.account-editor .question.user-entry-required .form_field > span.label::after,
.account-editor .question.user-entry-required .form_field > label::after,
.account-editor .question.user-entry-required .prop-group > .props > span.label::after,
.account-editor .question.user-entry-required .prop-group > .props > label::after,
.account-editor .prop.user-entry-required > span.label::after,
.account-editor .prop.user-entry-required > label::after,
.account-editor .question .user-entry-required > span.label::after,
.account-editor .question .user-entry-required > label::after {
  content: "\f069";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.5rem;
  font-weight: bold;
  font-style: normal;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  margin-left: 3px;
}
.account-add .question:not(.account-proposal) .form_field label:first-child,
.account-add .question:not(.account-proposal) .form_field span.label,
.account-add .question:not(.account-proposal) .form_field h1.title,
.account-editor .question:not(.account-proposal) .form_field label:first-child,
.account-editor .question:not(.account-proposal) .form_field span.label,
.account-editor .question:not(.account-proposal) .form_field h1.title {
  display: block;
  width: 100%;
  max-width: 300px;
  background: #7B7B7B;
  color: #fff;
  line-height: 1.2;
  padding: 9px 13px 8px;
  font-size: 1.25rem;
  margin-bottom: 15px;
  margin-top: 45px;
  text-transform: none;
  font-weight: 300;
}
.account-add .question:not(.account-proposal) .form_field label:first-child:before,
.account-add .question:not(.account-proposal) .form_field span.label:before,
.account-add .question:not(.account-proposal) .form_field h1.title:before,
.account-editor .question:not(.account-proposal) .form_field label:first-child:before,
.account-editor .question:not(.account-proposal) .form_field span.label:before,
.account-editor .question:not(.account-proposal) .form_field h1.title:before {
  font-size: 1.125rem;
  margin-right: 7px;
}
.account-add .question:not(.account-proposal) .form_field .extension_label-con .extension_label,
.account-editor .question:not(.account-proposal) .form_field .extension_label-con .extension_label {
  color: #7B7B7B;
  background-color: white;
  margin: 0;
  padding: 0;
}
.account-add .question:not(.account-proposal) .form_field.status-con .ts-wrapper + label,
.account-editor .question:not(.account-proposal) .form_field.status-con .ts-wrapper + label {
  margin-top: 1rem;
}
.account-add .question:not(.account-proposal) .form_field.wide label:first-child,
.account-add .question:not(.account-proposal) .form_field.wide span.label,
.account-add .question:not(.account-proposal) .form_field.wide h1.title,
.account-editor .question:not(.account-proposal) .form_field.wide label:first-child,
.account-editor .question:not(.account-proposal) .form_field.wide span.label,
.account-editor .question:not(.account-proposal) .form_field.wide h1.title {
  width: 100%;
}
.account-add .question .label-selector .label-group.user-entry-required .label-group-heading::after,
.account-editor .question .label-selector .label-group.user-entry-required .label-group-heading::after {
  content: "\f069";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.5rem;
  font-weight: bold;
  font-style: normal;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  margin-left: 3px;
}
.account-add .bu-selector-con .richlist,
.account-editor .bu-selector-con .richlist {
  display: flex;
  flex-wrap: wrap;
}
.account-add .bu-selector-con .element,
.account-editor .bu-selector-con .element {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100px;
  padding: 15px;
  line-height: 1.25;
  width: 100%;
  box-shadow: 0 0 10px rgba(213, 213, 213, 0.75);
  border: 1px solid #D5D5D5;
  border-radius: 8px;
  margin: 5px 0 20px;
  font-size: 1.25rem;
  color: rgb(var(--color-brand-main));
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
@media (min-width: 768px) {
  .account-add .bu-selector-con .element,
.account-editor .bu-selector-con .element {
    max-width: 47%;
    margin: 5px 1.5% 20px;
  }
}
@media (min-width: 1024px) {
  .account-add .bu-selector-con .element,
.account-editor .bu-selector-con .element {
    max-width: 23%;
    min-height: 130px;
    margin: 5px 1% 15px;
  }
}
.account-add .bu-selector-con .element.checked,
.account-editor .bu-selector-con .element.checked {
  background: rgb(var(--color-brand-main-dark));
  border: none;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.33);
  color: rgb(var(--color-brand-main-reverse));
}
.account-add .bu-selector-con .element.checked:before,
.account-editor .bu-selector-con .element.checked:before {
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #83898C;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(213, 213, 213, 0.75);
}
.account-add .bu-selector-con .element input[type=checkbox],
.account-editor .bu-selector-con .element input[type=checkbox] {
  visibility: hidden;
  z-index: -1;
  position: absolute;
}
.account-add .individualized-content button.btn,
.account-add .account-content button.btn,
.account-editor .individualized-content button.btn,
.account-editor .account-content button.btn {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-reverse));
  border: 2px solid rgb(var(--color-brand-main));
  border-radius: 2rem;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
  font-weight: 600;
  text-decoration: none;
}
.account-add .individualized-content button.btn.disabled, .account-add .individualized-content button.btn:disabled,
.account-add .account-content button.btn.disabled,
.account-add .account-content button.btn:disabled,
.account-editor .individualized-content button.btn.disabled,
.account-editor .individualized-content button.btn:disabled,
.account-editor .account-content button.btn.disabled,
.account-editor .account-content button.btn:disabled {
  opacity: 0.5;
}
.account-add .individualized-content button.btn.add:before,
.account-add .account-content button.btn.add:before,
.account-editor .individualized-content button.btn.add:before,
.account-editor .account-content button.btn.add:before {
  content: "\f055";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 5px;
  margin-top: -0.2em;
}
.account-add .individualized-content .individualized,
.account-add .individualized-content .account,
.account-add .account-content .individualized,
.account-add .account-content .account,
.account-editor .individualized-content .individualized,
.account-editor .individualized-content .account,
.account-editor .account-content .individualized,
.account-editor .account-content .account {
  display: block;
  width: 100%;
  border: 1px solid #D5D5D5;
  border-bottom: 3px solid #83898C;
  padding: 0.25rem 0.5rem;
}
.account-add .individualized-content .individualized:first-child,
.account-add .individualized-content .account:first-child,
.account-add .account-content .individualized:first-child,
.account-add .account-content .account:first-child,
.account-editor .individualized-content .individualized:first-child,
.account-editor .individualized-content .account:first-child,
.account-editor .account-content .individualized:first-child,
.account-editor .account-content .account:first-child {
  margin-top: 15px;
}
.account-add .individualized-content .individualized + .individualized,
.account-add .individualized-content .account + .individualized,
.account-add .account-content .individualized + .individualized,
.account-add .account-content .account + .individualized,
.account-editor .individualized-content .individualized + .individualized,
.account-editor .individualized-content .account + .individualized,
.account-editor .account-content .individualized + .individualized,
.account-editor .account-content .account + .individualized {
  margin-top: 5px;
}
.account-add .individualized-content .individualized .content,
.account-add .individualized-content .individualized .actions,
.account-add .individualized-content .account .content,
.account-add .individualized-content .account .actions,
.account-add .account-content .individualized .content,
.account-add .account-content .individualized .actions,
.account-add .account-content .account .content,
.account-add .account-content .account .actions,
.account-editor .individualized-content .individualized .content,
.account-editor .individualized-content .individualized .actions,
.account-editor .individualized-content .account .content,
.account-editor .individualized-content .account .actions,
.account-editor .account-content .individualized .content,
.account-editor .account-content .individualized .actions,
.account-editor .account-content .account .content,
.account-editor .account-content .account .actions {
  vertical-align: middle;
}
.account-add .individualized-content .individualized .content,
.account-add .individualized-content .account .content,
.account-add .account-content .individualized .content,
.account-add .account-content .account .content,
.account-editor .individualized-content .individualized .content,
.account-editor .individualized-content .account .content,
.account-editor .account-content .individualized .content,
.account-editor .account-content .account .content {
  width: calc(100% - 65px);
  display: inline-block;
}
.account-add .individualized-content .individualized .actions a,
.account-add .individualized-content .account .actions a,
.account-add .account-content .individualized .actions a,
.account-add .account-content .account .actions a,
.account-editor .individualized-content .individualized .actions a,
.account-editor .individualized-content .account .actions a,
.account-editor .account-content .individualized .actions a,
.account-editor .account-content .account .actions a {
  min-width: auto;
  overflow: hidden;
  white-space: nowrap;
  width: 4ch;
  border: 0;
  box-shadow: none;
  font-size: 1rem;
  padding: 8px;
  color: #83898C;
}
.account-add .individualized-content .individualized .actions a span,
.account-add .individualized-content .account .actions a span,
.account-add .account-content .individualized .actions a span,
.account-add .account-content .account .actions a span,
.account-editor .individualized-content .individualized .actions a span,
.account-editor .individualized-content .account .actions a span,
.account-editor .account-content .individualized .actions a span,
.account-editor .account-content .account .actions a span {
  display: block;
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute;
}
.account-add .individualized-content .individualized .actions a:hover,
.account-add .individualized-content .account .actions a:hover,
.account-add .account-content .individualized .actions a:hover,
.account-add .account-content .account .actions a:hover,
.account-editor .individualized-content .individualized .actions a:hover,
.account-editor .individualized-content .account .actions a:hover,
.account-editor .account-content .individualized .actions a:hover,
.account-editor .account-content .account .actions a:hover {
  background: transparent;
  text-decoration: none;
}
.account-add .individualized-content .individualized .actions a.remove:hover,
.account-add .individualized-content .account .actions a.remove:hover,
.account-add .account-content .individualized .actions a.remove:hover,
.account-add .account-content .account .actions a.remove:hover,
.account-editor .individualized-content .individualized .actions a.remove:hover,
.account-editor .individualized-content .account .actions a.remove:hover,
.account-editor .account-content .individualized .actions a.remove:hover,
.account-editor .account-content .account .actions a.remove:hover {
  color: #E53140;
}
.account-add .individualized-content .individualized .actions a.remove::before,
.account-add .individualized-content .account .actions a.remove::before,
.account-add .account-content .individualized .actions a.remove::before,
.account-add .account-content .account .actions a.remove::before,
.account-editor .individualized-content .individualized .actions a.remove::before,
.account-editor .individualized-content .account .actions a.remove::before,
.account-editor .account-content .individualized .actions a.remove::before,
.account-editor .account-content .account .actions a.remove::before {
  content: "\f2ed";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.875rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.account-add .individualized-content .individualized .actions a.edit:hover,
.account-add .individualized-content .account .actions a.edit:hover,
.account-add .account-content .individualized .actions a.edit:hover,
.account-add .account-content .account .actions a.edit:hover,
.account-editor .individualized-content .individualized .actions a.edit:hover,
.account-editor .individualized-content .account .actions a.edit:hover,
.account-editor .account-content .individualized .actions a.edit:hover,
.account-editor .account-content .account .actions a.edit:hover {
  color: rgb(var(--color-brand-main-dark));
}
.account-add .individualized-content .individualized .actions a.edit::before,
.account-add .individualized-content .account .actions a.edit::before,
.account-add .account-content .individualized .actions a.edit::before,
.account-add .account-content .account .actions a.edit::before,
.account-editor .individualized-content .individualized .actions a.edit::before,
.account-editor .individualized-content .account .actions a.edit::before,
.account-editor .account-content .individualized .actions a.edit::before,
.account-editor .account-content .account .actions a.edit::before {
  content: "\f303";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.875rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.account-add .question .phone_number_form .phone_number_format,
.account-editor .question .phone_number_form .phone_number_format {
  display: flex;
}
.account-add .question .phone_number_form .phone_number_format .label-after,
.account-editor .question .phone_number_form .phone_number_format .label-after {
  display: flex;
}
.account-add .question .phone_number_form .phone_number_format .label-after label,
.account-editor .question .phone_number_form .phone_number_format .label-after label {
  margin: 0 20px 0 5px;
}
.account-add .question .phone_number_form .nanp_number-con,
.account-editor .question .phone_number_form .nanp_number-con {
  margin-bottom: 0;
}
.account-add .question .phone_number_form .extension_label,
.account-editor .question .phone_number_form .extension_label {
  background: none;
  color: #7B7B7B;
  padding: 5px;
  margin: 30px 0 5px;
}

.account-add section.account-add h1 {
  color: rgb(var(--color-brand-main));
  margin: 0 22px 10px 0;
  width: 100%;
}
.account-add .quick-add-buttons {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #D5D5D5;
  text-align: right;
}
.account-add .quick-add-buttons span.submit_button {
  display: inline-block;
}
.account-add span.submit_button {
  display: none;
}

.account-editor span.submit_button {
  display: block;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #D5D5D5;
  text-align: right;
}
.account-editor .file_extra_upload_form,
.account-editor .image_extra_upload_form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-bottom: 5px solid rgb(var(--color-brand-main-dark));
  text-align: center;
}
.account-editor .file_extra_upload_form .ctb.label-after input.remove_file,
.account-editor .file_extra_upload_form .ctb.label-after input.remove_image,
.account-editor .image_extra_upload_form .ctb.label-after input.remove_file,
.account-editor .image_extra_upload_form .ctb.label-after input.remove_image {
  visibility: hidden;
}
.account-editor .file_extra_upload_form .ctb.label-after label.remove_file,
.account-editor .file_extra_upload_form .ctb.label-after label.remove_image,
.account-editor .image_extra_upload_form .ctb.label-after label.remove_file,
.account-editor .image_extra_upload_form .ctb.label-after label.remove_image {
  display: inline-block;
  text-transform: none;
  text-decoration: underline;
  cursor: pointer;
}
.account-editor .file_extra_upload_form .ctb.label-after label.remove_file:hover,
.account-editor .file_extra_upload_form .ctb.label-after label.remove_image:hover,
.account-editor .image_extra_upload_form .ctb.label-after label.remove_file:hover,
.account-editor .image_extra_upload_form .ctb.label-after label.remove_image:hover {
  text-decoration: none;
}
.account-editor .file_extra_upload_form .file_extra_onfile_message,
.account-editor .file_extra_upload_form .image_extra_onfile_message,
.account-editor .image_extra_upload_form .file_extra_onfile_message,
.account-editor .image_extra_upload_form .image_extra_onfile_message {
  display: inline-block;
  flex: 1 1 auto;
  padding: 0.5rem;
  margin: 0 1rem;
  background: #fff;
  border: 1px solid #83898C;
  color: #7B7B7B;
  line-height: 1;
}
.account-editor .image_extra_upload_form {
  line-height: 0;
}
.account-editor .image_extra_upload_form .upload_widget {
  order: 2;
  flex: 0 0 100%;
  margin: 1rem auto 0;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #EBEFF2;
  position: relative;
}
.account-editor .image_extra_upload_form .upload_widget:before {
  content: "Drag & Drop File Here";
  position: absolute;
  top: 2rem;
  left: 1.5rem;
  font-size: 1rem;
  color: #7B7B7B;
  text-transform: uppercase;
}
.account-editor .image_extra_upload_form .upload_widget:after {
  content: "or";
  position: absolute;
  top: 4rem;
  left: 1.5rem;
}
.account-editor .image_extra_upload_form .upload_widget .btn {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
.account-editor .image_extra_upload_form .upload_widget input[type=file] {
  display: inline-block;
  line-height: 1;
  border: 1px dotted #83898C !important;
  border-radius: 5px;
  padding: 5rem 1rem 1rem !important;
}
.account-editor .image_extra_upload_form img.image_preview {
  max-width: 50px;
  max-height: 100px;
}
.account-editor .file_extra_upload_form .file_extra_upload_field {
  margin: 0 auto;
  display: block;
}
.account-editor .account-proposal h2 {
  display: block;
  width: 100%;
  max-width: 300px;
  background: #7B7B7B;
  color: #fff;
  line-height: 1.2;
  padding: 9px 13px 8px;
  font-size: 1.25rem;
  margin-bottom: 15px;
  margin-top: 45px;
  text-transform: none;
  font-weight: 300;
}
.account-editor .account-proposal .form_field label,
.account-editor .account-proposal .prop-group-title h1 {
  font-size: 1rem;
  text-transform: none;
  color: #7B7B7B;
  font-weight: 500;
}
.account-editor .project-edit h1.title {
  display: block;
  width: 100%;
  max-width: 300px;
  background: #7B7B7B;
  color: #fff;
  line-height: 1.2;
  padding: 9px 13px 8px;
  font-size: 1.25rem;
  margin-bottom: 15px;
  margin-top: 45px;
  text-transform: none;
  font-weight: 300;
}
.account-editor .direct-selector > span.label {
  font-weight: 500;
  margin: 25px 0 0.125rem;
  display: block;
}

.invite-page {
  margin: 10px auto;
}
@media (max-width: 480px) {
  .invite-page__header {
    margin-top: 1.5rem;
  }
}
.invite-page .account-user-invite-con .required-notice {
  margin-top: 0;
}
.invite-page .nav-actions .back {
  position: static;
  top: auto;
  margin-bottom: 0.5rem;
  padding-left: 0;
}

.account-user-invite-con {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(213, 213, 213, 0.75);
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .account-user-invite-con {
    padding: 30px;
    margin-bottom: 25px;
  }
}
.account-user-invite-con h3 {
  text-align: left;
  line-height: 1;
  font-size: 1.875rem;
  font-weight: 700;
  color: rgb(var(--color-brand-main));
  margin-bottom: 1.25rem;
}
.account-user-invite-con .prop,
.account-user-invite-con .prop-group {
  margin-bottom: 15px;
}
.account-user-invite-con .prop.user-entry-required label::after {
  content: "\f069";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.5rem;
  font-weight: bold;
  font-style: normal;
  color: rgb(var(--color-brand-main-dark));
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  margin-left: 3px;
}
.account-user-invite-con .prop label {
  display: block;
}
@media (max-width: 480px) {
  .account-user-invite-con .principal-contact {
    display: flex;
    flex-direction: column;
  }
  .account-user-invite-con .principal-contact .prop-group {
    flex: 1 1 100%;
    flex-direction: column;
    margin-bottom: 0;
  }
  .account-user-invite-con .principal-contact .prop-group .prop {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 480px) {
  .account-user-invite-con .principal-contact .prop-group {
    display: flex;
    justify-content: space-between;
  }
  .account-user-invite-con .principal-contact .prop-group .prop {
    flex-basis: calc(50% - 0.5rem);
    margin-bottom: 0;
  }
}
.account-user-invite-con .principal-contact + .workspace-membership-label-selector .label-group .label-group-heading,
.account-user-invite-con .principal-contact + .workspace-membership-label-selector .label-group .label-group-name {
  color: #5C5C5C;
  font-size: 0.875rem;
  font-weight: 700;
}
.account-user-invite-con .principal-contact + .workspace-membership-label-selector .label-group .labeldomain .instructions {
  font-style: italic;
  margin: 5px 0;
}
.account-user-invite-con .principal-contact + .workspace-membership-label-selector .label-group .label-group-instructions {
  display: block;
  font-style: italic;
  margin: 5px 0;
}
.account-user-invite-con .principal-contact + .workspace-membership-label-selector .label-group .label-group-labels {
  display: block;
  margin: 0.5rem 0;
}
.account-user-invite-con .principal-contact + .workspace-membership-label-selector .label-group.user-entry-required .label-group-heading::after {
  color: rgb(var(--color-brand-main-dark));
}
.account-user-invite-con .prop.subspaces .val {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
.account-user-invite-con .prop.subspaces .ctb {
  display: flex;
  flex-flow: row nowrap;
}
.account-user-invite-con .prop.subspaces .ctb label {
  font-weight: 500;
  text-transform: none;
}
.account-user-invite-con .send-link-con .prop {
  margin-bottom: 0;
}
.account-user-invite-con label {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.account-user-invite-con .required-notice {
  display: block;
  font-size: 0.9rem;
  color: rgb(var(--color-brand-main-dark));
  font-style: italic;
  margin: -0.5rem 0 0.75rem;
}

.generated-link-con {
  padding: 15px 20px;
  background: #FFFFFF;
  border: 1px solid #83898C;
  margin-top: 1rem;
}
.generated-link-con .copy-get-link-text {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-reverse));
  border: 2px solid rgb(var(--color-brand-main));
  border-radius: 2rem;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
  font-weight: 600;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 10px;
  line-height: 1.2;
  margin-top: 10px;
}
.generated-link-con .copy-get-link-text.disabled, .generated-link-con .copy-get-link-text:disabled {
  opacity: 0.5;
}
.generated-link-con .copy-get-link-text:before {
  content: "\f0c5";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.875rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 5px;
}

.get-link-text {
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all;
  cursor: cell;
  word-break: break-all;
}

.link-delivery-options:not(:empty) {
  border-top: 1px solid #D5D5D5;
  padding-top: 10px;
  margin-top: 20px;
}

.get-lnk-option, .send-lnk-option {
  padding: 15px 15px 15px 30px;
  display: block;
}
.get-lnk-option input[type=radio], .send-lnk-option input[type=radio] {
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
.get-lnk-option input[type=radio]:checked ~ .get-link:before, .get-lnk-option input[type=radio]:checked ~ .send-link:before, .send-lnk-option input[type=radio]:checked ~ .get-link:before, .send-lnk-option input[type=radio]:checked ~ .send-link:before {
  background: #83898C;
}

.get-link-instruction,
.send-link-instruction {
  font-size: 0.875rem;
  line-height: 1.4;
  display: block;
  color: #83898C;
  font-style: italic;
}

.send-link-con .prop {
  width: 100%;
}

.bottom-actions {
  display: flex;
  justify-content: flex-end;
}

label.get-link, label.send-link {
  position: relative;
  font-size: 1.125rem;
  text-transform: none;
  text-indent: calc(-1ch - 18px);
  display: block;
  color: #7B7B7B;
  font-weight: 400;
}
label.get-link:before, label.send-link:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 0 2px #83898C;
  transition: background 0.15s ease;
  margin-right: 1ch;
  vertical-align: middle;
}

@media (min-width: 480px) {
  .contact-editor .principal-contact {
    margin-bottom: 1rem;
  }
  .contact-editor .principal-contact .prop-group {
    display: flex;
    justify-content: space-between;
  }
  .contact-editor .principal-contact .prop {
    flex-basis: calc(50% - 0.5rem);
    margin-bottom: 0;
  }
}
.contact-editor .val:empty:after {
  content: "Unspecified";
}

.account-import, .salesforce-import {
  max-width: 980px;
  margin: 0 auto;
}
.account-import .account-importer .instructions, .account-import .sfa-importer .instructions, .salesforce-import .account-importer .instructions, .salesforce-import .sfa-importer .instructions {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-style: italic;
  font-size: 0.9rem;
  line-height: 1.5;
}
.account-import .account-importer p.instructions, .account-import .sfa-importer p.instructions, .salesforce-import .account-importer p.instructions, .salesforce-import .sfa-importer p.instructions {
  padding-bottom: 0;
}
.account-import .account-importer .file-import, .account-import .sfa-importer .file-import, .salesforce-import .account-importer .file-import, .salesforce-import .sfa-importer .file-import {
  margin-bottom: 1rem;
}

.content-importer .persistence-actions {
  margin-top: 10px;
}

/* Can't use this because we're using root-defined colors

@function set-secondary-font-color($color) {
	@if ($color == #fff) {
		@return #a2a2a2;
	} @else if (lightness($color) > 50) {
		@return darken($color, 28%)
	} @else {
		@return lighten($color, 20%);
	}
}
 */
/* covers pages for the entire Bulk Add Module process */
.engages-main-content .box-wc > .text:first-child h1 {
  font-size: 2rem;
}

.bulk-module-send {
  margin-top: 1.5rem;
  padding: 30px;
  border: 1px solid transparent;
  box-shadow: 0 0 20px #D5D5D5;
}
.bulk-module-send .message {
  margin-bottom: 30px;
}
.bulk-module-send .add-module.instructions {
  display: inline-block;
  padding: 0 10px 10px 0;
  line-height: 1.5;
}
.bulk-module-send .num-accounts .label,
.bulk-module-send .num-accounts .number,
.bulk-module-send .num-contacts .label,
.bulk-module-send .num-contacts .number {
  font-size: 1.0625rem;
  font-weight: 700;
}
.bulk-module-send .num-accounts .count,
.bulk-module-send .num-contacts .count {
  cursor: pointer;
  color: rgb(var(--color-brand-main-dark));
}
.bulk-module-send .num-accounts .count .action,
.bulk-module-send .num-contacts .count .action {
  display: block;
}
.bulk-module-send .accounts.hidden {
  display: none;
}
.bulk-module-send .accounts:not(.hidden) {
  max-height: 400px;
  overflow: auto;
  box-shadow: 0 0 20px #dbe3e8;
  padding: 30px 30px 1px;
  margin-top: 4px;
}
.bulk-module-send .accounts .account {
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-bottom: 3px solid rgb(var(--color-brand-main-dark));
  margin: 0 5px 29px;
  padding: 15px;
}
.bulk-module-send .accounts .account .name {
  font-weight: 700;
  flex-basis: 100%;
  padding: 5px;
}
.bulk-module-send .accounts .account .contact {
  padding-left: 15px;
}
.bulk-module-send .select-module .instructions {
  font-size: 1.0625rem;
  font-weight: 700;
  margin-bottom: 0;
}
.bulk-module-send .select-module label {
  height: 1px;
  width: 1px;
  display: block;
  overflow: hidden;
  margin: 0;
}
.bulk-module-send .manage-mode.module-feed {
  margin: 0 0 30px;
}
.bulk-module-send .manage-mode.module-feed p.instructions {
  background-color: rgb(var(--color-brand-main-dark));
  border: 1px solid #D5D5D5;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 30px;
  margin-bottom: 30px;
}
.bulk-module-send .manage-mode.module-feed .module-wrapper {
  background-color: #fff;
  box-shadow: none;
}
.bulk-module-send .manage-mode.module-feed .module-wrapper:last-child {
  margin-bottom: 0;
}
.bulk-module-send .manage-mode.module-feed .instructions + .module-wrapper {
  margin-top: 0;
}
.bulk-module-send .bulk-send-actions {
  text-align: right;
  display: inline-block;
  width: 100%;
}
.bulk-module-send .bulk-send-actions .btn {
  font-size: 1rem;
  padding: 8px 15px 7px;
  width: auto;
}
.bulk-module-send .send-notifications,
.bulk-module-send .notification-options {
  line-height: 1.5;
}
.bulk-module-send .send-notifications {
  margin: 30px 0;
  padding-top: 30px;
  border-top: 1px solid #D5D5D5;
  line-height: 2;
}
.bulk-module-send .send-notifications > .label:not(.label-after),
.bulk-module-send .send-notifications > label:not(.label-after) {
  font-weight: 700;
}
.bulk-module-send .notification-options div,
.bulk-module-send .notification-options span.label-after {
  display: block;
}
.bulk-module-send .notification-options > div {
  margin: 30px 0;
}
.bulk-module-send .notification-options > div > .label:not(.label-after),
.bulk-module-send .notification-options > div > label:not(.label-after) {
  font-weight: 700;
}
.bulk-module-send .notification-options .email .recipient.roles span {
  padding-left: 15px;
}
.bulk-module-send .notification-options .counts .miwt-collapsable a.trigger {
  color: rgb(var(--color-brand-main-dark));
}
.bulk-module-send .notification-options .counts .miwt-collapsable.shown .miwt-collapsable-content {
  max-height: 400px;
  overflow: scroll;
  display: block !important;
}
.bulk-module-send .notification-options .counts .miwt-collapsable.shown .miwt-collapsable-content .account {
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-bottom: 3px solid rgb(var(--color-brand-main-dark));
  margin: 5px 0 30px;
  padding: 15px;
}
.bulk-module-send .notification-options .counts .miwt-collapsable.shown .miwt-collapsable-content .account .name {
  font-weight: 700;
  flex-basis: 100%;
  padding: 5px;
}
.bulk-module-send .notification-options .counts .miwt-collapsable.shown .miwt-collapsable-content .account .contact {
  padding-left: 15px;
}
.bulk-module-send .notification-options,
.bulk-module-send .no-notification-con {
  display: block;
  border: 1px solid #D5D5D5;
  padding: 1px 30px;
  margin: 30px 0;
}
.bulk-module-send .no-notification-con {
  background-color: rgb(var(--color-brand-main-dark));
  color: rgb(var(--color-brand-main-reverse));
  padding: 30px 30px;
}

.bulk-add-v3 .current-step-con {
  min-height: 60vh;
}
.bulk-add-v3 .instructions {
  margin-bottom: 1rem;
  font-style: italic;
}
.bulk-add-v3 div.actions {
  margin: 1rem 0;
}
.bulk-add-v3 div.actions button.btn.back {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-reverse));
  border: 2px solid rgb(var(--color-brand-main));
  border-radius: 2rem;
  font-family: "Montserrat", Arial, Verdana, sans-serif;
  font-weight: 600;
  text-decoration: none;
}
.bulk-add-v3 div.actions button.btn.back.disabled, .bulk-add-v3 div.actions button.btn.back:disabled {
  opacity: 0.5;
}
.bulk-add-v3 .module-select-step .module-feed {
  max-width: 1400px;
  margin: auto;
}
.bulk-add-v3 .default-group-select-step .buttons {
  -moz-column-count: 3;
       column-count: 3;
}
@media (max-width: 480px) {
  .bulk-add-v3 .default-group-select-step .buttons {
    -moz-column-count: 1;
         column-count: 1;
  }
}
@media (min-width: 1400px) {
  .bulk-add-v3 .default-group-select-step .buttons {
    -moz-column-count: 4;
         column-count: 4;
  }
}
.bulk-add-v3 .publish-step .publish-option .publish-time > label {
  margin-bottom: 10px;
}
.bulk-add-v3 .publish-step .publish-option .publish-time .hour-selector {
  display: block;
}
@media (min-width: 768px) {
  .bulk-add-v3 .publish-step .publish-option .publish-time .hour-selector {
    display: flex;
  }
}
.bulk-add-v3 .publish-step .publish-option .publish-time .hour-selector .miwt-calendar {
  margin: 10px 0;
}
@media (min-width: 768px) {
  .bulk-add-v3 .publish-step .publish-option .publish-time .hour-selector .miwt-calendar {
    margin: 0;
  }
}
.bulk-add-v3 .publish-step .publish-option .publish-time .hour-selector .timezone {
  margin: auto 5px;
}
.bulk-add-v3 .publish-step .publish-option .create-task-options {
  margin-bottom: 0.5rem;
}
@media (min-width: 1280px) {
  .bulk-add-v3 .publish-step .publish-option .create-task-options {
    margin-left: 1.5rem;
  }
}
.bulk-add-v3 .publish-step .publish-time {
  padding-bottom: 1em;
}
.bulk-add-v3 .publish-step .publish-time .unpublish-later .instructions {
  font-weight: 300;
  font-size: 1em;
  margin: unset;
}
.bulk-add-v3 .publish-step .miwt-calendar {
  display: flex;
  flex-flow: row nowrap;
  max-width: 300px;
  padding: 0;
}
.bulk-add-v3 .publish-step .miwt-calendar button.open {
  position: relative;
  height: 30px;
  width: 30px;
  color: #078345;
  background-color: transparent !important;
  border: none !important;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  position: unset;
}
.bulk-add-v3 .publish-step .miwt-calendar button.open:before {
  content: "\f271";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  font-weight: 700;
  font-style: normal;
  color: #078345;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 30px;
  text-align: center;
}
.bulk-add-v3 .publish-step .miwt-calendar button.open:disabled {
  cursor: not-allowed;
}
.bulk-add-v3 .publish-step .miwt-calendar button.open:not([disabled]) {
  cursor: pointer;
}
.bulk-add-v3 .publish-step .miwt-calendar button.open:hover {
  background-color: rgba(7, 131, 69, 0.2);
  border-color: rgba(7, 131, 69, 0.2);
}
.bulk-add-v3 .publish-step .miwt-calendar button.clear {
  position: relative;
  height: 30px;
  width: 30px;
  color: #DE683C;
  background-color: transparent !important;
  border: none !important;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  position: unset;
}
.bulk-add-v3 .publish-step .miwt-calendar button.clear:before {
  content: "\f00d";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  font-weight: 700;
  font-style: normal;
  color: #DE683C;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 30px;
  text-align: center;
}
.bulk-add-v3 .publish-step .miwt-calendar button.clear:disabled {
  cursor: not-allowed;
}
.bulk-add-v3 .publish-step .miwt-calendar button.clear:not([disabled]) {
  cursor: pointer;
}
.bulk-add-v3 .publish-step .miwt-calendar button.clear:hover {
  background-color: rgba(222, 104, 60, 0.2);
  border-color: rgba(222, 104, 60, 0.2);
}
.bulk-add-v3 .progress-report-con tr.status-pending td.col-workspace .workspace:before {
  content: " ";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.25rem;
}
.bulk-add-v3 .progress-report-con tr.status-success td.col-workspace .workspace:before {
  content: "\f058";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  color: #399C6A;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.25rem;
}
.bulk-add-v3 .progress-report-con tr.status-error td.col-workspace .workspace:before {
  content: "\e417";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  color: #e53140;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.25rem;
}
.bulk-add-v3 .progress-report-con tr.status-error-notify td.col-workspace .workspace:before {
  content: "\f06a";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  color: #DE683C;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.25rem;
}
.bulk-add-v3 .progress-report-con ul.notify-users li {
  list-style-type: none;
}
.bulk-add-v3 .progress-report-con ul.notify-users li.status-success:before {
  content: "\f058";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.8rem;
  font-weight: bold;
  font-style: normal;
  color: #399C6A;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0.25rem 0.25rem 0.25rem -1.25rem;
}
.bulk-add-v3 .progress-report-con ul.notify-users li.status-error:before {
  content: "\e417";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.8rem;
  font-weight: bold;
  font-style: normal;
  color: #e53140;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0.25rem 0.25rem 0.25rem -1.25rem;
}

.test-email-con .message-container {
  width: 100%;
  margin-left: auto;
  margin-right: 0.625rem;
  margin-top: 1.25rem;
}
.test-email-con a.send-test {
  text-align: right;
  display: block;
}

.test-email-con .test-email-form {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #dbe3e8;
}
.test-email-con .test-email-form button {
  margin: 1rem 0.5rem 0 0;
}

.prop-editor .task-relations .subspace-relations .modules .val {
  display: block;
}
.prop-editor .task-relations .subspace-relations .memberships .val {
  display: block;
}
.prop-editor .task-due-date .hour-selector {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar {
  display: flex;
  flex-flow: row nowrap;
  max-width: 300px;
  padding: 0;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.open {
  position: relative;
  height: 30px;
  width: 30px;
  color: #078345;
  background-color: transparent !important;
  border: none !important;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  position: unset;
  margin-left: 1ch;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.open:before {
  content: "\f271";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  font-weight: 700;
  font-style: normal;
  color: #078345;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 30px;
  text-align: center;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.open:disabled {
  cursor: not-allowed;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.open:not([disabled]) {
  cursor: pointer;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.open:hover {
  background-color: rgba(7, 131, 69, 0.2);
  border-color: rgba(7, 131, 69, 0.2);
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.clear {
  position: relative;
  height: 30px;
  width: 30px;
  color: #DE683C;
  background-color: transparent !important;
  border: none !important;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  position: unset;
  margin-right: 1ch;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.clear:before {
  content: "\f00d";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  font-weight: 700;
  font-style: normal;
  color: #DE683C;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 30px;
  text-align: center;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.clear:disabled {
  cursor: not-allowed;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.clear:not([disabled]) {
  cursor: pointer;
}
.prop-editor .task-due-date .hour-selector .miwt-calendar button.clear:hover {
  background-color: rgba(222, 104, 60, 0.2);
  border-color: rgba(222, 104, 60, 0.2);
}
.prop-editor .task-due-date .hour-selector .timezone {
  padding-left: 10px;
}

.task-dlg .task-due-date {
  padding: 5px;
}
@media (max-width: 480px) {
  .task-dlg .task-due-date .hour-selector .miwt-calendar {
    margin-bottom: 0.5rem;
  }
}

#account-sales-team {
  padding-bottom: 1px;
}
@media (min-width: 992px) {
  #account-sales-team {
    padding-bottom: 10px;
  }
}

.account-reps-con {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
@media (min-width: 992px) {
  .account-reps-con {
    justify-content: flex-start;
  }
}

.account-rep-con {
  width: 137px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  order: 2;
}
.account-rep-con header {
  margin-bottom: -0.5rem;
}
.account-rep-con header .persistence-actions {
  display: block;
  text-align: right;
  width: 100%;
  margin-right: 0;
}
.account-rep-con header .persistence-actions .btn {
  color: transparent;
  background: transparent;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  width: 1rem;
  overflow: hidden;
  white-space: nowrap;
}
.account-rep-con header .persistence-actions .btn + .btn {
  margin-left: 0.25ch;
}
.account-rep-con header .persistence-actions .btn::before {
  transition: opacity 0.5s ease;
  color: #83898C;
  font-size: 1rem;
  opacity: 0.9;
}
.account-rep-con header .persistence-actions .btn:hover, .account-rep-con header .persistence-actions .btn:focus {
  background: transparent;
  color: transparent;
}
.account-rep-con header .persistence-actions .btn:hover::before {
  opacity: 1;
}
.account-rep-con header .persistence-actions .btn.primary::before {
  content: "\f02e";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.875rem;
  font-weight: 500;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.account-rep-con header .persistence-actions .btn.remove::before {
  content: "\f00d";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.875rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.account-rep-con.primary-rep {
  order: 1;
}
.account-rep-con.primary-rep .persistence-actions .btn.primary:before {
  color: rgb(var(--color-brand-main));
  font-weight: 700;
}
.account-rep-con .profile-image {
  width: 137px;
  height: 137px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}
.account-rep-con footer {
  margin-top: 0.35rem;
  text-align: center;
}
.account-rep-con footer .name {
  font-weight: 700;
}

.sales-rep-bulk-action h1 {
  border-bottom: 1px solid;
}
.sales-rep-bulk-action .instructions {
  margin-left: 5px;
}
.sales-rep-bulk-action .user-listing[data-rep-count="0"] .selected-reps-title {
  display: none;
}
.sales-rep-bulk-action .account-reps-con {
  margin-bottom: 1rem;
}
.sales-rep-bulk-action .selected-reps-title {
  margin-bottom: 1rem;
}
.sales-rep-bulk-action .role-selector {
  margin: 0 0 1rem;
}

.available-reps-title,
.selected-reps-title {
  flex-basis: 100%;
  color: #55595b;
  font-size: 1.75rem;
  font-weight: 400;
  margin: 5px;
}

.module-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.125rem;
}
.module-menu ul {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}
.module-menu li > .menu-label {
  padding: 0.5rem 0 0.5rem 0.5rem;
}
.module-menu li li > .menu-label {
  padding-left: 1.5rem;
  background-color: #f7f7f7;
}
.module-menu li li li > .menu-label {
  padding-left: 2.5rem;
  background-color: #fff;
}
.module-menu li li li li > .menu-label {
  padding-left: 3.5rem;
  background-color: #f7f7f7;
}
.module-menu li li li li li > .menu-label {
  padding-left: 4.5rem;
  background-color: #fff;
}
.module-menu.root > li > .menu-label {
  background-color: rgb(var(--color-brand-main));
}
.module-menu.root > li > .menu-label.selected {
  background-color: rgb(var(--color-brand-main-dark));
}
.module-menu.root > li > .menu-label .search,
.module-menu.root > li > .menu-label .sub-menu-toggle,
.module-menu.root > li > .menu-label .menu-label-btn,
.module-menu.root > li > .menu-label .expand-all,
.module-menu.root > li > .menu-label .collapse-all,
.module-menu.root > li > .menu-label .more.action {
  color: #fff;
}
.module-menu .menu-label {
  display: flex;
  justify-content: space-between;
  border-top: 0;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 1px;
  border-style: solid;
  border-color: rgb(var(--color-brand-main));
  padding: 3px 4px;
}
.module-menu .menu-label.selected {
  background-color: #EBEFF2;
}
.module-menu .menu-label .menu-label-btn {
  text-decoration: none;
  flex-grow: 1;
  line-height: 25px;
}
.module-menu .menu-label .sub-menu-toggle {
  flex: 0 0 16px;
  width: 16px;
  height: 25px;
  text-decoration: none;
  vertical-align: middle;
  line-height: 25px;
}
.module-menu .menu-label .menu-item-actions {
  flex: 0 0 auto;
  padding-left: 0.5rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.module-menu .menu-label .menu-item-actions > .action {
  text-decoration: none;
  line-height: 25px;
  padding-right: 0.25rem;
  display: inline-block;
  min-width: 1rem;
  text-align: center;
}
.module-menu .menu-label .more-action-con {
  position: relative;
  margin-right: 0.25rem;
}
.module-menu .menu-label .more.action {
  line-height: 25px;
  cursor: pointer;
  display: inline-block;
  min-width: 1rem;
  text-align: center;
}
.module-menu .menu-label .popup-actions {
  display: none;
  position: absolute;
  right: -0.5rem;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
}
.module-menu .menu-label .popup-actions .action {
  display: block;
  padding: 3px 5px;
  margin: 2px;
  font-size: smaller;
}
.module-menu .menu-label .popup-actions.visible {
  display: block;
}
.module-menu .leaf-item .menu-label-btn {
  margin-left: 16px;
}